/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
import { message } from "antd";
import { useState, useContext, useCallback, useEffect } from "react";

import * as api from "../api";
import { AppContext } from "../store/AppContext";
import { STORAGE_ALIAS } from "../utils/constants";

const useFacts = ({ country: countryId, issue: issueId, age, language }) => {
  const [facts, setFacts] = useState([]);
  const { updateResources, resources, selectedLanguage } =
    useContext(AppContext);

  const storeData = useCallback((data, storageAlias) => {
    const dataString = JSON.stringify(data);
    localStorage.setItem(storageAlias, dataString);
  }, []);

  const getFactsResources = useCallback(async () => {
    updateResources([]);
    try {
      const { data } = await api.getFactsResources({
        countryId,
        issueId,
        age,
        language,
      });
      console.log(data);
      const { resources } = data;
      updateResources([...resources]);
      storeData(resources, STORAGE_ALIAS.resources);
    } catch (error) {
      message.error(error.message);
    }
  }, [age, issueId, countryId, language, storeData, updateResources]);

  const getFacts = useCallback(async () => {
    try {
      const { data } = await api.getFactsList({
        countryId,
        issueId,
        age,
        language,
      });
      let facts = [...Object.values(data.facts)];
      facts = facts.filter((f) => !!f);
      setFacts([...facts]);
      storeData(facts, STORAGE_ALIAS.facts);
    } catch (error) {
      message.error(error.message);
    }
  }, [age, issueId, countryId, language, storeData]);

  const isValidated = useCallback(
    () => countryId && issueId && age && language,
    [age, issueId, countryId, language]
  );

  useEffect(() => {
    if (!isValidated()) return;
    getFacts();
    getFactsResources();
  }, [selectedLanguage]);

  return { facts, resources };
};

export default useFacts;
